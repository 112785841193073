export const MUTATION_SET_PROJECTS = 'MUTATION_SET_PROJECTS';
export const MUTATION_SET_CATEGORIES = 'MUTATION_SET_CATEGORIES';
export const MUTATION_SET_PROJECT_DETAILS = 'MUTATION_SET_PROJECT_DETAILS';
export const MUTATION_SET_DARK_MODE = 'MUTATION_SET_DARK_MODE';
export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_LOADING_COLOR = 'MUTATION_SET_LOADING_COLOR';
export const ACTION_POPULATE_PROJECTS = 'ACTION_POPULATE_PROJECTS';
export const ACTION_POPULATE_CATEGORIES = 'ACTION_POPULATE_CATEGORIES';
export const ACTION_POPULATE_PROJECT_DETAILS = 'ACTION_POPULATE_PROJECT_DETAILS';
export const ACTION_SET_LOADING_COLOR = 'ACTION_SET_LOADING_COLOR';
